var timelineManager = (function(){

    function init()
    {
        if (!$(".timeline-component").length) {
            return;
        }


        $(window).on("load", function () {
            $(".timeline-component").each(function(){
                stepsTitles = $(this).find(".steps-list-place .title-container");
                alignStepsTitleHeights(stepsTitles);
            })
        })
    }

    function alignStepsTitleHeights(titles)
    {
        maxHeight = 0;
        titles.each(function(){
            if ($(this).height() > maxHeight) {
                maxHeight = $(this).height();
            }
        })
        titles.height(maxHeight);
    }

    return {
        init:init
    }
})(jQuery)