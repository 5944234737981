var roundedBoxesSliderManager = (function () {
    var roundedBoxesCarousel;
    var indexBox = 0;
    var roundedBoxesSliderObj = $(".section.rounded-boxes-slider .owl-carousel");

    function init(){
        $(document).ready(function () {
            if(roundedBoxesSliderObj.length){
                roundedBoxesSliderObj.each(function(){
                    initCarousel($(this));
                    alignHeight($(this));
                })
                
            }
        })
    }

    function alignHeight(el){
        if(ENV.isEditmode) return;
        let roundedBoxes = $(".owl-item .single-rounded-box-container", el);
        let maxHeight = 0;
        roundedBoxes.each(function(){
            currentHeight = $(this).innerHeight();
            if(currentHeight > maxHeight) maxHeight = currentHeight;
        })

        roundedBoxes.each(function(){
            $(this).css('height', maxHeight);
        })
    }

    function initCarousel(el){
        $(".item", el).each( function (k,v){
            if ($(this).hasClass('active')){
                indexBox = k;
            }
        });

        var boxesCount = $(".single-rounded-box-container", el).length;
        var cardsPerRow = el.data("cards-per-row") ? el.data("cards-per-row") : 3
        
        roundedBoxesCarousel = el.owlCarousel({
            mouseDrag: true && !ENV.isEditmode,
            navText: [
                "<i class='prev-icon'></i>",
                "<i class='next-icon'></i>"
            ],
            dots: false,
            center: boxesCount > cardsPerRow,
            loop: boxesCount > cardsPerRow && !ENV.isEditmode,
            responsive:{
                0:{
                    items: 1,
                    stagePadding: 0,
                    singleItem: true,
                    dots: false,
                    center: true,
                    loop: true && !ENV.isEditmode,
                    nav:boxesCount > 1
                },
                992: {
                    items: 2,
                    loop: true && !ENV.isEditmode,
                    nav:boxesCount > 2
                },
                1200: {
                    items: cardsPerRow,
                    stagePadding: 0,
                    margin: 15,
                    nav:boxesCount > cardsPerRow
                }
            }
        });

        // if(boxesCount > 3 || true){
        //     $(".owl-nav").attr('style', 'display: inherit !important');
        // } else {
        //     $(".owl-nav").attr('style', 'display: none !important');
        // }
    }

    return {
        init: init
    }
})(jQuery);